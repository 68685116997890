<template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="85px">
			<el-form-item class="el_form_item" label="子用户手机">
				<el-input class="el_input" v-model="form.sub_user_tel" placeholder="子账号手机号码" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="子用户姓名">
				<el-input class="el_input" v-model="form.sub_user_name" placeholder="子账号姓名" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="用户状态">
				<el-select class="el_input" v-model="form.status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="正常" value="1"></el-option>
					<el-option label="停用" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="用户类型">
				<el-select class="el_input" v-model="form.type" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="全数据" value="1"></el-option>
					<el-option label="部分数据" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="备注搜索">
				<el-input class="el_input" v-model="form.mark" placeholder="子账号备注" clearable></el-input>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" style="background-color:#795A25;border: none;" @click="page_ser">查询</el-button>
				<el-button type="primary" style="background-color:#FCF8ED;border: 1px solid #795A25;color:#795A25;" @click="ser_para_clear">清空条件</el-button>
				<el-button type="success" style="background-color:#FCF8ED;border: 1px solid #795A25;color:#795A25;" @click="open_add_view">添加</el-button>
				<el-button type="success" style="background-color:#795A25;border: none;" v-if="company_info.is_sub_price ==1" @click="sub_user_transfer(2)">关闭子账户转账功能</el-button>
				<el-button type="success" style="background-color:#795A25;border: none;" v-else @click="sub_user_transfer(1)">开启子账户转账功能</el-button>
				
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column label="添加时间">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.creat_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="姓名">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.sub_user_info.name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="手机号码">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.sub_user_info.tel}}</div>
					</template>
				</el-table-column>
				<el-table-column label="子账户余额">
					<template slot-scope="scope">
						<div class="tab_line_item" style="margin-right: 20px;">{{scope.row.price}} 
							<el-button  v-if="company_info.is_sub_price !=2"  @click="sub_user_money_operate(scope.row,1)" size="mini" type="text">转入</el-button>
							<el-button  v-if="company_info.is_sub_price !=2"  @click="sub_user_money_operate(scope.row,2)" size="mini" type="text">转出</el-button>
							</div>
					</template>
				</el-table-column>
				<!-- <el-table-column label="子账户转账功能">
					<template slot-scope="scope">
						<el-button @click="sub_user_transfer(1)" size="mini" type="text">开启</el-button>
						<el-button @click="sub_user_transfer(2)" size="mini" type="text" style="color:red">关闭</el-button>
					</template>
				</el-table-column> -->
				<el-table-column label="类型">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.type_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="状态">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.status_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="备注">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.mark}}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="90px">
					<template slot-scope="scope">
						<el-button @click="open_edit_view(scope.row)" size="mini" type="text">修改</el-button>
						<el-button @click="del(scope.row)" size="mini" type="text" style="color:red">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		></el-pagination>
		
		<!-- 添加页面 -->
		<el-dialog top="3vh" title="添加子账户" width="700px" :visible.sync="add_view.is_show">
			<el-form status-icon label-position="left" label-width="100px">
				<el-form-item label="用户手机" >
					<el-input class="el_input" v-model="add_view.form.sub_user_tel" type="number" >
						<el-button @click="get_sub_user_info" type="success" slot="append" style="width:112px">查找</el-button>
					</el-input>
				</el-form-item>
				<el-form-item label="用户姓名" v-if="!add_user_info">
					<el-input class="el_input" v-model="add_view.form.sub_user_name" disabled=""></el-input>
				</el-form-item>
				<el-form-item label="用户类型" >
					<el-select class="el_input" v-model="add_view.form.type" clearable >
						<el-option label="全数据(所有运单查看、支付、发票申请，如需关闭联系客服)" value="1"></el-option>
						<el-option label="子账户数据(子账户运单查看、支付、发票申请，如需关闭联系客服)" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="备注" >
					<el-input class="el_input" v-model="add_view.form.mark" ></el-input>
				</el-form-item>
				<el-form-item label="用户角色" >
					<el-select class="el_input" v-model="add_view.form.id" clearable>
						<el-option v-for="(item,index) in menu_list" :label="item.name" :value="item.id" :key="index"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="用户姓名" v-if="add_user_info">
					<el-input class="el_input" v-model="add_view.form.name" ></el-input>
				</el-form-item>
				<el-form-item label="用户手机号" v-if="add_user_info">
					<el-input class="el_input" v-model="add_view.form.tel" ></el-input>
				</el-form-item>
				<el-form-item label="用户地址" v-if="add_user_info">
					<el-input class="el_input" v-model="add_view.form.addr" ></el-input>
				</el-form-item>
				<el-form-item label="用户身份证号" v-if="add_user_info">
					<el-input class="el_input" v-model="add_view.form.id_card_num" ></el-input>
				</el-form-item>
				<el-form-item label="身份证正面" v-if="add_user_info">
					<div class="img_upl_btn">
						<div class="btn" @click="choose_img('id_card_img1')" >
							<img v-if="img_upl.id_card_img1.src" :src="img_upl.id_card_img1.src" class="avatar" style="width:300px;height:300px">
							<i v-else class="el-icon-plus avatar-uploader-icon" style="width:140px;font-size: 150px;"></i>
						</div>
						<div>
						</div>
					</div>
				</el-form-item>
				<el-form-item label="身份证反面" v-if="add_user_info">
					<div class="img_upl_btn">
						<div class="btn" @click="choose_img('id_card_img2')">
							<img v-if="img_upl.id_card_img2.src" :src="img_upl.id_card_img2.src" class="avatar" style="width:300px;height:300px">
							<i v-else class="el-icon-plus avatar-uploader-icon" style="width:140px;font-size: 150px;"></i>
						</div>
					</div>
				</el-form-item>
			</el-form>
			<div style="text-align:center">
				<el-button type="primary" v-if="!add_user_info" @click="sub_account_add()">提交</el-button>
				<el-button type="primary" v-if="add_user_info" @click="user_info_add">添加</el-button>
			</div>
		</el-dialog>
		
		<!-- 修改页面 -->
		<el-dialog top="3vh" title="修改子账户" width="700px" :visible.sync="edit_view.is_show">
			<el-form status-icon label-position="left" label-width="70px">
				<el-form-item label="用户状态" >
					<el-select class="el_input" v-model="edit_view.form.status" clearable>
						<el-option label="正常" value="1"></el-option>
						<el-option label="停用" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="用户类型" >
					<el-select class="el_input" v-model="edit_view.form.type" clearable>
						<el-option label="全数据（所有运单查看、支付、发票申请，如需关闭联系客服）" value="1"></el-option>
						<el-option label="子账户数据（子账户运单查看、支付、发票申请，如需关闭联系客服）" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="用户备注" >
					<el-input class="el_input" v-model="edit_view.form.mark"></el-input>
				</el-form-item>
				<el-form-item label="用户状态" >
					<el-select class="el_input" v-model="edit_view.form.status" clearable>
						<el-option label="正常" value="1"></el-option>
						<el-option label="停用" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="用户角色" >
					<el-select class="el_input" v-model="edit_view.form.role_id" clearable>
						<el-option v-for="(item,index) in menu_list" :label="item.name" :value="item.id" :key="index"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div style="text-align:center">
				<el-button type="primary" @click="sub_account_edit">提交</el-button>
			
			</div>
			
	       </el-dialog>
		   <!-- 资金转移弹窗 -->
				<el-dialog title="请输入金额" :visible.sync="dialogFormVisible" :modal='false' width="400px" @close='close'>
					<el-input placeholder="" v-model="cargo_money" disabled>
					<template slot="prepend" style="width:1000px" >当前货主余额</template>
				</el-input>
				<el-input placeholder="" disabled v-model="sub_user_money" style="margin-top:10px;">
					<template slot="prepend" >{{set_sub_price_type==1?'当前转入账户余额':'当前转出账户余额'}}</template>
				</el-input>
				<el-input placeholder="请输入金额" v-model="set_sub_price_much" style="margin-top:10px;">
					<template slot="prepend">转移金额</template>
				</el-input>
				<div slot="footer" class="dialog-footer">
					<el-button @click="dialogFormVisible=false">取 消</el-button>
					<el-button type="primary" @click="set_sub_price_confirm">确 定</el-button>
				</div>
		    </el-dialog>
	</div>
</template>

<script>
	import {mapState} from 'vuex'
	export default {
		data() {
			return {
				//菜单列表
				menu_list:{},
				//搜索条件
				form: {				
					status:'',//状态(1:正常,2:停用)
					type:'',//类型(1:全权限,2:仅能查看全部数据,3:只能查看自己的数据)
					sub_user_tel:'',//手机号码
					sub_user_name:'',//用户姓名
					mark:'',//备注

				},
				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},
				add_user_info:false,//未查找到用户
				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},
				dialogFormVisible:false,
				//添加页面数据
				add_view:{
					is_show:false,
					form:{
						sub_user_tel:'',//手机号码
						sub_user_name:'',//用户姓名
						sub_user_num:'',//用户编号
						type:'',//类型(1:全权限,2:仅能查看全部数据,3:只能查看自己的数据)
						mark:'',//备注
						id_card_img1:'',//身份证正面
						id_card_img2:'',//身份证反面
						name:'',//添加的用户姓名
						tel:'',//手机号
						addr:'',//地址
						id_card_num:'',//身份证号
						id:'',//用户角色
					}
				},
				cargo_money:'',//当前选中余额
                sub_user_money:'',//当前选中子账户余额
				set_sub_price_much:'',//转移金额
				set_sub_price_type:'',//1转出 2转入
				set_sub_price_data:'',//当前选中子账户数据
				img_upl:{
				//身份证正面
					id_card_img1: {
						src: '', //图片地址
						key: '', //图片key
						is_choose: false,
						is_upl: false,
						bucket: 'idcard1',
					},

					//身份证背面
					id_card_img2: {
						src: '', //图片地址
						key: '', //图片key
						is_choose: false,
						is_upl: false,
						bucket: 'idcard2',
					},
				},
				//修改页面数据
				edit_view:{
					buf:{},
					is_show:false,
					form:{
						id:'',
						status:'',//状态(1:正常,2:停用)
						type:'',//类型(1:全权限,2:仅能查看全部数据,3:只能查看自己的数据)
						mark:'',//备注
						role_id:'',
					}
				},
				//用户添加弹窗
				add_para:{
					form:{
						name:'',
						menu:[],
					},
					is_show:false,
				}
			}
		},
		computed:{
			...mapState(['user_info',"company_info"])
		},
		created() {
			//读取数据
			this.get_page_data()
		},
		methods: {
			//添加新的子账户
			user_info_add(){
				if(!this.add_view.form.id_card_img1){
					this.$my.other.msg({
						type:"info",
						str:'请上传身份证正面'
					});
					return;
				}
				if(!this.add_view.form.id_card_img2){
					this.$my.other.msg({
						type:"info",
						str:'请上传身份证反面'
					});
					return;
				}
				this.$my.net.req({
					data:{
						mod:'truck_sub_user',
						ctr:'shipper_sub_user_add',
						name:this.add_view.form.name,
						tel:this.add_view.form.tel,
						id_card_num:this.add_view.form.id_card_num,
						addr:this.add_view.form.addr,
						id_card_img1:this.add_view.form.id_card_img1,
						id_card_img2:this.add_view.form.id_card_img2,
					},
					callback:(res)=>{
						//认证成功 执行添加接口
						this.sub_account_add(res.user_num)
					}
				})
			},
			//识别图片
			img_ocr(img_name){

				if(['id_card_img2'].indexOf(img_name)!=-1){
					return
				}
				var img_type='id_card';
				var img_url=this.$my.qiniu.make_src('idcard1', this.img_upl.id_card_img1.key)
				//调用接口
				this.$my.net.req({
					data:{
						mod:'res',
						ctr:'img_ocr',
						img_type:img_type,
						img_url:img_url
					},
					callback:(res)=>{
						this.add_view.form.name = res.name
						this.add_view.form.id_card_num = res.id_card_num
						this.add_view.form.addr = res.address
					}
				});
			},
			//上传图片
			upl_img(img_name) {

				//取出相应对象
				let ctr_obj = this.img_upl[img_name]

				//上传
				this.$my.qiniu.upl({
					bucket: ctr_obj.bucket,
					key: ctr_obj.key, //xxxxxx?v=1 或者 null
					file_path: ctr_obj.src,
					other_para: ctr_obj,
					callback: (data, other_para) => {
						other_para.is_upl = true;
						other_para.key = data.key;
						//置入key
						switch(img_name){
							case 'id_card_img1':
								this.add_view.form.id_card_img1 = data.key
								break;

							case 'id_card_img2':
                                this.add_view.form.id_card_img2 = data.key
								break;
						}
						this.img_ocr(img_name);
					}
				});
			},
			//选择图片
			choose_img(img_name) {

				//取出相应对象
				let ctr_obj = this.img_upl[img_name]

				//选择图片
				this.$my.qiniu.choose_img({
					success: (res) => {
						ctr_obj.src = res.src;
						ctr_obj.is_choose = true;
						ctr_obj.is_upl = false;
						this.upl_img(img_name)
					}
				});
			},
			//子账户弹窗关闭
			close(){
				this.set_sub_price_much = ''
			},
			set_sub_price_confirm(){
				this.$my.net.req({
					data:{
						mod:'truck_sub_user',
						ctr:'set_sub_price',
						id:this.set_sub_price_data.id,
						aos:this.set_sub_price_type,
						much:this.set_sub_price_much
					},callback:(data)=>{
						this.$my.other.msg({
							type:"success",
							str:'操作成功'
						});
						this.get_page_data()
						this.dialogFormVisible = false
					}
				})
			},
			//子账户金额转移
			sub_user_money_operate(data,type){
				//查看货主余额
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'balance_info_by_appp_user',
					},
					callback:(res)=>{
						//更新页面内的显示余额
						this.cargo_money=res.sub_total_price
						this.sub_user_money = data.price
						this.dialogFormVisible = true

						this.set_sub_price_type = type

						this.set_sub_price_data = data
					},
				});
			},
			//子账户转账功能
			sub_user_transfer(type){
				let form={
					mod:'truck_sub_user',
					ctr:'sub_price_status',
					is_sub_price:type
				}
				if(type ==1){
					this.company_info.is_sub_price =1
					this.$my.net.req({
						data:form,
						callback:(data)=>{
							this.$my.other.msg({
								type:"success",
								str:'开启成功'
							});
						}
					})
				}else{
					//询问
					this.$my.other.confirm({
						content:"关闭子账户余额功能将清空设置的子账户余额,是否确认?",
						confirm:()=>{
							
							//调用接口
							this.$my.net.req({
								data:form,
								callback:(data)=>{
									this.$my.other.msg({
										type:"success",
										str:'操作成功'
									});
									this.get_page_data()
									this.company_info.is_sub_price =2
								}
							})
						}
					});
				}
			},

			//删除
			del(item){
				
				//询问
				this.$my.other.confirm({
					content:"确定删除子用户:'"+item.sub_user_info.name+"'",
					confirm:()=>{
						
						//调用接口
						this.$my.net.req({
							data:{
								mod:'truck_sub_user',
								ctr:'sub_user_del_by_main_user',
								id:item.id
							},
							callback:(data)=>{
								this.get_page_data()
							}
						});
					}
				});
			},
			
			//修改子账户
			open_edit_view(item){//打开修改界面
				//缓存数据
				this.edit_view.buf={...item}
				
				//置入数据
				this.edit_view.form.id=item.id
				this.edit_view.form.status=item.status
				this.edit_view.form.type=item.type
				this.edit_view.form.mark=item.mark
			    this.edit_view.form.role_id=item.role_id
			
				//读取数据
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'sub_user_role_list',
					},
					callback:(data)=>{
						this.menu_list = data.list
						//打开	
						this.edit_view.is_show=true;
					}
				});

			},
			sub_account_edit(){//执行修改
				console.log(111)
				let post_data={
					mod:'truck_sub_user',
					ctr:'sub_user_edit_by_main_user',
					id:this.edit_view.form.id,
					set:{}
				};
				
				if(this.edit_view.form.status!=this.edit_view.buf.status&&['1','2'].indexOf(this.edit_view.form.status)!=-1){//用户状态
					post_data.set.status=this.edit_view.form.status
				}
				
				if(this.edit_view.form.type!=this.edit_view.buf.type&&['1','2','3'].indexOf(this.edit_view.form.type)!=-1){//用户类型
					post_data.set.type=this.edit_view.form.type
				}
				
				if(this.edit_view.form.mark!=this.edit_view.buf.mark&&this.edit_view.form.mark.trim()){//用户备注
					post_data.set.mark=this.edit_view.form.mark.trim()
				}
				post_data.set.role_id = this.edit_view.form.role_id
				if(Object.keys(post_data.set).length==0){//没有编辑需求
					this.edit_view.is_show=false;
					return;
				}
				
				//提交数据
				this.$my.net.req({
					data:post_data,
					callback:(data)=>{
						this.edit_view.is_show=false;
						this.get_page_data()
					}
				});
			},
			
			//添加子账户
			open_add_view(){//打开添加界面
			    
				this.add_view.form.sub_user_tel=''
				this.add_view.form.sub_user_name=''
				this.add_view.form.sub_user_num=''
				this.add_view.form.type=''
				this.add_view.form.mark=''
				this.add_view.form.name=''
				this.add_view.form.tel=''
				this.add_view.form.id_card_num=''
				this.add_view.form.addr=''
				this.add_view.form.id_card_img1=''
				this.add_view.form.id_card_img2=''
				this.add_view.form.id = ''
				this.add_user_info = false
				
				
				//读取数据
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'sub_user_role_list',
					},
					callback:(data)=>{
						this.menu_list  = data.list
						this.add_view.is_show=true;
					}
				});
			},
			get_sub_user_info(){//搜索子用户信息
				
				if(!this.$my.check.is_tel(this.add_view.form.sub_user_tel)){//子用户手机
					this.$my.other.msg({
						type:"warning",
						str:'请输入正确的用户手机号码'
					});
					return;
				}
				
				let user_info=this.user_info
				if(this.add_view.form.sub_user_tel==user_info.tel){//子用户手机
					this.$my.other.msg({
						type:"warning",
						str:'不能添加自己'
					});
					return;
				}
				
				//调用接口
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'user_list_by_app_user',
						tel:this.add_view.form.sub_user_tel
					},
					callback:(data)=>{

						if(data.list.length==0){
							this.add_user_info = true
							this.$my.other.msg({
								type:"warning",
								str:'未查找到该用户'
							});
							return;
						}

						if(data.list.length>1){
							this.$my.other.msg({
								type:"warning",
								str:'读取结果有误'
							});
							return;
						}

						//取出用户数据
						let user_info=data.list[0];
						
						if(user_info.real_status!=2){//未实名
							this.$my.other.msg({
								type:"warning",
								str:'未实名'
							});
							return;
						}
						
						this.add_view.form.sub_user_num=user_info.user_num;
						this.add_view.form.sub_user_name=user_info.name;
					}
				});
			},
			sub_account_add(user_num){//执行添加
				let post_data={
					mod:'truck_sub_user',
					ctr:'sub_user_add_by_main_user',
				};
				if(!user_num){
					if(!this.$my.check.is_tel(this.add_view.form.sub_user_tel)){//用户手机
						this.$my.other.msg({
							type:"warning",
							str:'请输入正确的用户手机号码'
						});
						return;
					}else post_data.sub_user_tel=this.add_view.form.sub_user_tel
					
					if(!this.add_view.form.sub_user_num.trim()){//用户编号
						this.$my.other.msg({
							type:"warning",
							str:'请先查找用户'
						});
						return;
					}else post_data.sub_user_num=this.add_view.form.sub_user_num.trim()
				}else{
					post_data.sub_user_tel=this.add_view.form.tel
					post_data.sub_user_num=user_num.trim()
				}

				post_data.role_id = this.add_view.form.id
				if(['1','2'].indexOf(this.add_view.form.type)==-1){//用户类型
					this.$my.other.msg({
						type:"warning",
						str:'请选择正确的用户类型'
					});
					return;
				}else post_data.type=this.add_view.form.type
				
				if(this.add_view.form.mark.trim()){//用户备注
					post_data.mark=this.add_view.form.mark.trim()
				}
				
				//提交数据
				this.$my.net.req({
					data:post_data,
					callback:(data)=>{
						this.add_view.is_show=false;
						this.get_page_data()
					}
				});
			},

			//清空查询条件
			ser_para_clear(){
				this.form={
					status:'',//状态(1:正常,2:停用)
					type:'',//类型(1:全权限,2:仅能查看全部数据,3:只能查看自己的数据)
					sub_user_tel:'',//手机号码
					sub_user_name:'',//用户姓名
					mark:'',//备注
				}
				this.get_page_data()
			},
			
			//搜索
			page_ser(){

				//读取第一页
				this.page.p=1

				//读取
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取货源列表
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_sub_user',
						ctr:'sub_user_list_by_main_user',
						...this.form,
						...this.page
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//成交时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);
							
							//状态
							switch(item.status){
								case '1':item.status_text="正常";break;
								case '2':item.status_text="停用";break;
							}
							
							//类型(1:全权限,2:仅能查看全部数据,3:只能查看自己的数据)
							switch(item.type){
								case '1':item.type_text="全数据";break;
								case '2':item.type_text="部分数据";break;
							}
						}

						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		
		overflow: auto;
	}
	.ser_form{
		display: flex;
	
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 90px);
	}
	.el_form_item{
		width: 16.6672%;
	}
	.el_input{
		width:94%;
		
	}
	.btn_left{
		margin: 0;
	}
		//图片上传按钮
	.img_upl_btn {

		.status {
			color: #999;
		}

		.btn {
			
			cursor: pointer;
		}
	}
	.menu_list{
		background-color:#eee;
		height: 500px;
		overflow: auto;
		padding: 0 20px;
		margin-top: 10px;
		.children_list{
			margin-left: 20px;
		}
	}
</style>